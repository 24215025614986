

.progressVisualFull {
  display: flex;
  height: 8px;
  margin: 14px 0px 2px 0px;
  border-radius: 5px;
}

.progressVisualPart {
  /* Number of the seconds for complete animation */
  transition: width 2s;
}

.progressVisualLabel {
  font-size: 11px;
  font-weight: bold;
  margin-top:10px;
}

h3.progressVisualMarker.top {
  font-size:11px;
  font-weight: bold;
  margin-top:-14px;
  white-space:nowrap;
}

h3.progressVisualMarker.bottom {
  font-size:11px;
  font-weight: bold;
  margin-top:+8px;
  white-space:nowrap;
}
