// Here you can add other styles

div.fullWidth {
  width: 100%;
}

body {
  font-size: 14px;
}

.ui.pagination.menu .active.item {
  background-color: rgba(184, 195, 252, 0.4);
}

.ui.core-card.no-margin {
  margin: 0;
}

div.core-card {
  margin-bottom: 20px;
}

mark.highlight {
  background-color: yellow;
  padding: 0;
}

span.no-highlight {
  margin: 0;
}

div.core-card-header {
  background-color: #f0f3f5 !important;
}

.main .container-fluid {
  padding: 0 0 0 30px;
}

.ui.cards:last-child {
  margin-bottom: 1px;
}

.ui.progress {
  height: auto;
}

.ui.list .list {
  padding: 0;
}

.ui.list .list > .item .header,
.ui.list > .item .header {
  margin-bottom: 5px;
}

div.no-padding {
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* Fix flex resizing of form groups */
.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
  width: 100%;
}

.ui.selectable.table tbody tr:hover,
.ui.table tbody tr td.selectable:hover {
  color: rgba(21, 99, 177, 0.95) !important;
}

table.ui td.total-cell,
table.ui th.total-cell {
  text-align: right;
  font-weight: bold;
}

.ql-editor {
  white-space: normal !important;
}

.ui.transparent.qty-picker > input {
  text-align: center;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.ui.transparent.qty-picker > input:focus {
  background-color: #ddd !important;
  border-bottom: 1px solid #666 !important;
}

.itemPriceHeader {
  font-size: 3.5rem;
  font-weight: bold;
}

.itemPriceRange {
  margin-top: -10px;
}

.itemPriceGp {
  font-weight: bold;
}

.itemPriceGpPositive {
  color: green;
}

.itemPriceGpNegative {
  color: red;
}

.list-pick-segment {
  cursor: pointer;
}

.list-pick-segment:hover {
  cursor: pointer;
  background-color: #9ecdff;
}

.no-margin {
  margin-left: 0;
  margin-right: 0;
}

.ui.grid .no-margin {
  margin-left: 0;
  margin-right: 0;
}

.ui.form .no-margin {
  margin: 0;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.full-width-datepicker {
  width: 100%;
}

.line-height-1 {
  line-height: 1;
  display: inline-block;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.search-result-count {
  font-size: 10px;
  font-weight: bold;
  margin: 3px;
}

.moneyInput {
  input {
    text-align: right !important;
  }
}

.centerInput {
  input {
    text-align: center !important;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

// CardBox Styling
.cardbox {
  .header {
  }
}

// Leaflet height
.leaflet-container {
  height: 300px;
}

// Override the Container Margins and paddings to free up space on mobile
@media only screen and (max-width: 767px) {
  .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    padding-left: 0;
    padding-right: 0;
  }

  .main .container-fluid {
    padding: 0 10px 0 10px;
  }
}
