
.ccSlider {
  position: relative;
  width: 100%;
  height: 80px;
  border: 0;
}

.ccSliderRail {
  position: absolute;
  width: 100%;
  height: 10px;
  margin-top: 35px;
  border-radius: 5px;
  background-color: #ccc;
}

.ccSliderHandle {
  position: absolute;
  margin-left: -15px;
  margin-top: 25px;
  z-index: 2;
  width: 30px;
  height: 30px;
  border: 0;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: #2C4870;
  color: #333;
}

.ccSliderTrack {
  position: absolute;
  height: 10px;
  z-index: 1;
  margin-top: 35px;
  background-color: #546C91;
  border-radius: 5px;
  cursor: pointer;
}

.ccSliderTickLine {
  position: absolute;
  margin-top: 52px;
  margin-left: -0.5px;
  width: 1px;
  height: 8px;
  backgroundColor: silver;
}

.ccSliderTickValue {
  position: absolute;
  margin-top: 60px;
  font-size: 10px;
  text-align: center;
}