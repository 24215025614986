// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/free.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
//@import "~@fortawesome/fontawesome-free/css/fontawesome.min.css";
// Import Simple Line Icons Set
//@import '~simple-line-icons/css/simple-line-icons.css';
@import '~simple-line-icons/dist/styles/simple-line-icons.css';
// Import React-Confirm-Alert styles
@import '~react-confirm-alert/src/react-confirm-alert.css';
// Import Toastr Styles
@import '../node_modules/react-redux-toastr/src/styles/index';
// Import Quill GUI editor css
@import '../node_modules/react-quill/dist/quill.snow.css';
// Import Sweet Progress Bar
@import "~react-sweet-progress/lib/style.css";
// Import Semantic UI styles
@import '../node_modules/semantic-ui-css/semantic.min.css';
// Import DatePicker styles
@import "../node_modules/react-datepicker/dist/react-datepicker.css";
// Import Leaflet styles
// @import "../node_modules/leaflet/dist/leaflet.css";
//@import "https://unpkg.com/leaflet@1.7.1/dist/leaflet.css";
// Import Main styles for this application
@import './scss/style.scss';
