@import url('https://fonts.googleapis.com/css?family=Lato');

.realtime-countdown-rings {
  font-family: 'Lato', sans-serif;

  * {
    box-sizing: border-box;
  }

  .countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .countdown-item {
    color: #111;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .countdown-item span {
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
  }

  .countdown-svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.large {
    .countdown-item {
      font-size: 40px;
      line-height: 30px;
      margin: 10px;
      padding-top: 10px;
      width: 100px;
      height: 100px;
    }

    .countdown-item span {
      font-size: 12px;
    }

    .countdown-svg {
      width: 100px;
      height: 100px;
    }
  }

  &.medium {
    margin-bottom: 8px;

    .countdown-item {
      font-size: 20px;
      line-height: 15px;
      margin: 8px;
      margin-bottom: 0px;
      padding-top: 5px;
      width: 50px;
      height: 50px;
    }

    .countdown-item span {
      font-size: 6px;
    }

    .countdown-svg {
      width: 51px;
      height: 51px;
    }
  }

  &.small {
    margin-bottom: 8px;

    .countdown-item {
      font-size: 10px;
      line-height: 7px;
      margin: 2px;
      margin-bottom: 0px;
      padding-top: 3px;
      width: 25px;
      height: 25px;
    }

    .countdown-item span {
      font-size: 4px;
    }

    .countdown-svg {
      width: 26px;
      height: 26px;
    }
  }
}
